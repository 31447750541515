import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlingInterceptor } from './shared/interceptors/error-handling.interceptor';
import { SharedModule } from './shared/shared.module';
import { NgxLoadingModule } from 'ngx-loading';
import { HomeComponent } from './pages/home/home.component';
import { ConsultationComponent } from './pages/consultation/consultation.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthLogin } from './shared/auth/auth.login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadFileService } from './shared/services/upload-file/upload-file.service';
import { PaymentResultComponent } from './pages/payment-result/payment-result.component';
import { SuccessComponent } from './pages/consultation/success/success.component';
import { LandingComponent } from './pages/landing/landing.component';
import { RatingComponent } from './pages/rating/rating.component';
import { ModalRatingComponent } from './pages/garages/modal-rating/modal-rating.component';
import { CalendarAuthCallbackComponent } from './pages/calendar-auth-callback/calendar-auth-callback.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ConsultationComponent,
    PaymentResultComponent,
    SuccessComponent,
    LandingComponent,
    RatingComponent,
    ModalRatingComponent,
    CalendarAuthCallbackComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgxLoadingModule.forRoot({}),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right'
    }),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    AuthLogin,
    UploadFileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
