<div>
  <app-header
    [isFullWidth]="[ROUTER.CONSULTATION, ROUTER.HOME, ROUTER.HISTORY, ROUTER.SERVICE_HISTORY].includes(page)  ? true : false "></app-header>
  <main>
    <ng-content>
    </ng-content>
  </main>
  <app-footer *ngIf="isShowFooter"></app-footer>
</div>
<div class="reference-box">
  <ng-content [data]="data" select="[referenceBox]"></ng-content>
</div>