import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataLayerService } from './shared/services/google-tag-manager/data-layer.service';
import { SeoService } from './shared/services/seo/seo.service';
import { UtilsService } from './shared/services/utils/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'gocar-garage-website';
  isLoading: boolean = false
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private dataLayerService: DataLayerService,
    @Inject(PLATFORM_ID) platformId: Object,
    private seoService: SeoService
  ) {
    this.router.events.subscribe(event => { // subscribe to router events
      if (event instanceof NavigationEnd) { //if our event is of our interest
        const url = event.urlAfterRedirects;
        if (url) {
          this.seoService.updateMetaByRouter(url.slice(1))
        }
        //END UPDATE META TAG AND TITLE
        const isBrowser = isPlatformBrowser(platformId);
        if (isBrowser) {
          this.dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
        }

        console.log("url change =====> ", url)
        // NOTE: if open from inappbrowser then alway redirect to landing page
        if (url == '/' || url == '') {
          if (this.utilsService.isGocarApp) {
            this.router.navigate(['landing'], { replaceUrl: true })
          }
        }
      }
    });

    // UDPATE PARAM FOR INAPPBROWSER
    this.activatedRoute.queryParams.subscribe((params: any) => {
      console.log("param =====> ", params)
      this.utilsService.setDataFromGocarApp(params)
    });
  }
  ngOnInit(): void {
    this.loadChatJS();
  }

  private loadChatJS(): void {
    const src = `${environment.gocar_support.goCarChatJSUrl}?key=${environment.gocar_support.key}`;
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
  }
}
