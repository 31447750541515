<!-- Google Tag Manager 1 (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MQ33LLM" height="0" width="0"
                style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->

<!-- Google Tag Manager 2 (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=G-52PDTT0V9T" height="0" width="0"
                style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
<router-outlet></router-outlet>

<ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true }"></ngx-loading>