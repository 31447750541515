// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // gogarage_api: "http://localhost:8031/gocar-garage-api/website",
  gogarage_api: 'https://garage-api.gocardev.com/gocar-garage-api/website',
  authorization_key: 'gogarage',
  authorization_value: '8HFsEl6TpTtDwy1P3vCWHLgqpXI6Z1wP',
  siteKeyCaptcha: '6LfIwawUAAAAAFKkkeXdwYjRAOZFimjnoaFzHA9a',

  GOCAR_HELPER_API_URL: 'https://dev-gocar-helper-api-3i65asokvq-as.a.run.app',
  GOCAR_HELPER_SECRET_KEY: "gocar_helper_key",
  GOCAR_HELPER_SECRET_VALUE: "2XsE3ydpFukcObeEp6QV8IKJZInhDdmW",
  apiEncryptKey: "7ZuKqj8oHbY3L1V",
  gocar_support: {
    key: 'U2FsdGVkX18KMYzw59c40AcIvNmt6aUZiJ7NN244cIphL4Ag3FirC8V1rkXK%2BSQIN7hKFFV5PPAAm3c%2FQqjOccDAHrkcLh3EE6oa%2FH%2BCQJ0%3D',
    goCarChatWebsiteUrl: "https://dev-gocar-chat-website-3i65asokvq-as.a.run.app",
    goCarChatJSUrl: "https://dev-golly-dashboard-3i65asokvq-as.a.run.app/assets/chat.setup.js"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
