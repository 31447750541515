import { Component, Input, OnInit } from '@angular/core';
import { APP_ROUTES } from '../../common/app-routes';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  @Input() isShowFooter = true;
  @Input() page = '';

  ROUTER = APP_ROUTES

  constructor() { }

  ngOnInit(): void {
  }

}
